import Cubby from '../../../assets/images/fulls/Cubby.jpeg'
import Garden1 from '../../../assets/images/fulls/Garden1.jpeg'
import Garden2 from '../../../assets/images/fulls/Garden2.jpeg'
import leanTo from '../../../assets/images/fulls/leanTo.jpeg'
//import sideTable from '../../../assets/images/fulls/sideTable.jpg'
// import thumb01 from '../../../assets/images/thumbs/01.jpg'
// import thumb03 from '../../../assets/images/thumbs/03.jpg'
// import thumb04 from '../../../assets/images/thumbs/04.jpg'
// import thumb05 from '../../../assets/images/thumbs/05.jpg'
// import thumb06 from '../../../assets/images/thumbs/06.jpg'

export const DEFAULT_IMAGES = [
  {
    id: '1',
    source: Cubby,
    thumbnail: Cubby,
    caption: 'Basement Cubby',
    description: 'Custom basement cubby with access door to hide supplies.',
  },
  {
    id: '2',
    source: Garden1,
    Garden2,
    thumbnail: Garden1,
    caption: 'Frontyard Garden',
    description: 'Custom built garden and garden beds.',
  },
  {
    id: '3',
    source: leanTo,
    thumbnail: leanTo,
    caption: 'LeanTo Structure',
    description: 'Custom free-standing leanto structure.',
  },
  // {
  //   id: '4',
  //   source: sideTable,
  //   thumbnail: sideTable,
  //   caption: 'Side Table ',
  //   description: 'Custom side table for a walkway.',
  // },
  // {
  //   id: '5',
  //   source: full05,
  //   thumbnail: thumb05,
  //   caption: 'Photo 5',
  //   description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  // },
  // {
  //   id: '6',
  //   source: full06,
  //   thumbnail: thumb06,
  //   caption: 'Photo 6',
  //   description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  // },
]
